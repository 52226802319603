<template>
  <div class="course-right" id="courseRight">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12">
          <div class="card" v-if="currentLesson">
            <div class="card-header border-radius-none border-bottom-0 bg-dark">
              <div class="justify-content-between w-100 d-flex align-items-center">
                <h3 class=" mb-0 text-truncate-line-2 text-white line-clamp-1">{{ currentLesson.name }}</h3>
                <button v-on:click="prepareUploadingFile(currentLesson, currentSection)"
                        class="btn btn-light float-end text-nowrap"
                        data-bs-toggle="modal" data-bs-target="#uploadModal">
                  <i class="fe fe-video"></i> Nộp bài
                </button>
              </div>
            </div>
            <div class="card-body p-0">
              <div v-html="currentLesson.content" class="mb-3" v-if="currentLesson.content"></div>
              <template v-if="currentLesson.attachments.length">
                <template v-for="media in currentLesson.attachments" :key="'media' + media.id">
                  <div
                      v-if="parseInt(media.attachmentPivot.is_courseware) === 0 && isCoccoc && (media.media_type === 'video' || media.media_type === 'audio')">
                    <div class="alert alert-danger">
                      <p>Hiện tại video không xem được trên trình duyệt Coccoc.</p>
                      <p>Bạn vui lòng sử dụng trình duyệt Chrome hoặc Firefox.</p>
                      <p>
                        <a href="https://www.google.com/intl/vi_vn/chrome/" class="btn btn-primary">Tải trình duyệt
                          Chrome</a>&nbsp;
                        <a href="https://www.mozilla.org/vi/firefox/new/" class="btn btn-danger">Tải trình duyệt
                          Firefox</a>
                      </p>
                    </div>
                  </div>
                  <div
                      v-if="parseInt(media.attachmentPivot.is_courseware) === 0 && (!isCoccoc || (media.media_type !== 'video' && media.media_type !== 'audio'))">
                    <div class="player-wrap" v-if="media.media_type === 'video' || media.media_type === 'audio'">
                      <player
                          :type="media.media_type"
                          :options="getPlayerOptions(media, course)"
                          :key="media.uid"
                          @ended="handleFinishLesson(currentLesson, currentSection)">
                      </player>
                    </div>
                    <WebViewer v-if="media.media_type === 'other' || media.media_type === 'image'"
                               :initialDoc="media.link"
                    />
                  </div>
                </template>
              </template>
            </div>
            <div class="card-footer border-radius-none border-top-0 bg-dark text-white">
              <nav class="navbar navbar-dark p-0">
                <button v-on:click="toggleCourseRight" class="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#courseAccordion" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <router-link class="btn btn-light" :to="{name: 'CourseDetail', params: {code: course.code}}">
                  Quay lại bài giảng
                </router-link>
                <button
                    v-if="!currentLesson.attachments.length || (currentLesson.attachments[0].media_type !== 'video' && currentLesson.attachments[0].media_type !== 'audio')"
                    v-on:click="handleFinishLesson(currentLesson, currentSection)"
                    class="btn btn-light float-end">
                  <i class="fe fe-check-circle"></i> Hoàn thành
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
      :class="showCourseLeft ? 'course-left show collapse navbar-collapse bg-white' : 'course-left collapse navbar-collapse bg-white'"
      id="courseAccordion">
    <scrollbar>
      <ul class="list-group list-group-flush course-list">
        <li class="list-group-item">
          <div class="progress" style="height: 6px;">
            <div class="progress-bar bg-success" role="progressbar"
                 :style="'width: ' + myCourse.userCoursePivot.percent + '%;'"
                 :aria-valuenow="myCourse.userCoursePivot.percent" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <small>{{ myCourse.userCoursePivot.percent }}% hoàn thành</small>
        </li>
        <li class="list-group-item" v-for="(section, index) in course.sections" :key="'section' + index">
          <a :class="section.viewed ? 'd-flex align-items-center text-primary text-decoration-none h4 mb-0' : 'd-flex align-items-center text-inherit text-decoration-none h4 mb-0'"
             data-bs-toggle="collapse"
             :href="'#collapse' + index" role="button" aria-expanded="false" :aria-controls="'collapse' + index">
            <div class="me-auto">
              {{ section.name }}
            </div>
            <span class="chevron-arrow  ms-4">
              <i :class="index === currentSectionIndex ? 'fe fe-chevron-down fs-4' : 'fe fe-chevron-up fs-4'"></i>
            </span>
          </a>
          <div :class="index === currentSectionIndex ? 'collapse show' : 'collapse'" :id="'collapse' + index"
               data-bs-parent="#courseAccordion">
            <div class="py-4 nav" id="course-tabOne" role="tablist" aria-orientation="vertical"
                 style="display: inherit;">
              <div v-for="(lesson, i) in section.lessons" :key="'lesson_' + index + '_' + i">
                <a href="#" :title="lesson.name" v-on:click="openLesson(index, i, lesson, section)">
                  <div :class="getLessonClass(lesson, index, i)">
                    <div class="text-truncate">
                    <span :class="getLessonIconColorClass(lesson, index, i)"><i
                        :class="getLessonIconClass(lesson)"></i></span>
                      <span>{{ lesson.name }}</span>
                    </div>
                  </div>
                </a>
                <div class="mb-3">
                  <ul v-if="lesson.attachments.length">
                    <template v-for="attachment in lesson.attachments" :key="'attachment' + attachment.uid"
                              :class="attachment.uid">
                      <li v-if="parseInt(attachment.attachmentPivot.is_courseware) === 1">
                        <a :title="attachment.name" class="text-inherit" :href="attachment.link" target="_blank">
                          <small><i class="fe fe-link"></i> {{ cutString(attachment.name, 30) }}</small>
                        </a>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </scrollbar>
    <button class="btn btn-primary w-100 border-radius-none" v-on:click="hideCourseLeft">Đóng lại</button>
  </div>

  <input type="file" ref="file" multiple v-on:change="handleFileUpload"/>

  <div class="modal fade" id="uploadModal" tabindex="-1" role="dialog" aria-labelledby="uploadModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="uploadModalLabel">
            Nộp bài
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="uploadDone">
            <Svg/>
            <div class="align-items-center justify-content-between text-center">
              <div class="text-dark-success mb-3">
                <svg class="bi flex-shrink-0" width="40" height="40">
                  <use xlink:href="#check-circle-fill"></use>
                </svg>
              </div>
              <div>
                <span class="w-100">Xin chúc mừng, bạn đã nộp bài thành công!</span>
              </div>
            </div>
          </div>
          <div v-if="!uploadDone">
            <div v-on:click="addFile"
                 class="cursor-pointer dropzone border-dashed d-flex align-items-center justify-content-between text-center">
              <span class="w-100">Chọn file upload</span>
            </div>
            <div class="progress mt-4" v-if="uploadPercent > 0">
              <div class="progress-bar progress-bar-striped bg-success" role="progressbar"
                   :style="`width: ${uploadPercent}%`" :aria-valuenow="uploadPercent" aria-valuemin="0"
                   aria-valuemax="100"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button id="finishLessonModalOpen" class="d-none" data-bs-toggle="modal" data-bs-target="#finishLessonModal">
    Hòan thành
  </button>

  <div class="modal fade" id="finishLessonModal" tabindex="-1" role="dialog" aria-labelledby="finishLessonModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="finishLessonModalLabel">
            Hoàn thành bài học
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <Svg/>
          <div class="align-items-center justify-content-between text-center">
            <div class="text-dark-success mb-3">
              <svg class="bi flex-shrink-0" width="40" height="40">
                <use xlink:href="#check-circle-fill"></use>
              </svg>
            </div>
            <div>
              <span class="w-100">Xin chúc mừng, bạn đã hoàn thành bài học!</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal id="viewMessageModal" :type="messageModalType" :message="messageModalMessage" :title="messageModalTitle"
         :message-detail="messageModalMessageDetail"/>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Svg from "@/components/Svg";
import Scrollbar from "vue3-smooth-scrollbar";
import {getUser} from "@/core/services/jwt.service";
import WebViewer from "@/components/WebViewer";
import Modal from "@/components/Modal";
import {cutString, openModal} from "@/core/services/utils.service";
import Player from "@/components/Player";

export default {
  name: 'CourseView',
  components: {
    Svg,
    Player,
    WebViewer,
    Modal,
    Scrollbar
  },
  data() {
    return {
      course: {
        code: "",
        name: "",
        intro: "",
        sections: []
      },
      myCourse: {
        userCoursePivot: {
          percent: 0
        }
      },
      lessonsViewed: [],
      currentLessonIndex: "0-0",
      currentSectionIndex: 0,
      currentLesson: null,
      currentSection: null,
      showProgress: false,
      uploadPercent: 0,
      uploadDone: false,
      messageModalType: "",
      messageModalMessage: "",
      messageModalTitle: "",
      messageModalMessageDetail: "",
      playerOptions: {
        autoplay: true,
        loop: true,
        controls: true,
        width: 689,
        height: 388,
        poster: "",
        sources: []
      }
    }
  },
  methods: {
    loadMySchool() {
      let query = `query {
        mySchool {
          is_suspend
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data.mySchool && data.data.mySchool.is_suspend) {
              openModal("viewMessageModal");
              this.messageModalMessage = "Trường học cần đóng phí để thực hiện chức năng này";
              this.messageModalType = "danger";
              this.messageModalTitle = "Thông báo";
            } else {
              this.loadCourse();
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            this.messageModalTitle = "Thông báo";
          });
    },
    loadCourse() {
      let user = getUser();
      let user_id = parseInt(user.id) || 0;

      let query = `query {
        course(code:"${this.$route.params.code}") {
          id
          code
          name
          intro
          welcome
          school {
            is_suspend
          }
          sections(orderBy: [{column: "position", order: ASC}]) {
            name
            code
            lessons(orderBy: [{column: "position", order: ASC}]) {
              id
              code
              name
              content
              attachments {
                id
                uid
                name
                hls
                link
                media_type
                mime_type
                extension
                attachmentPivot {
                  is_courseware
                }
              }
            }
          }
          thumbnail {
            link
            media_type
          },
          lessonsViewed(where: {AND: [{column: "user_id", value: ${user_id}}]}) {
            lesson_id
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data.course) {
              this.course = data.data.course;
              if (this.course.sections.length) {
                this.currentSection = this.course.sections[0];
                if (this.course.sections[0].lessons && this.course.sections[0].lessons.length) {
                  this.currentLesson = this.course.sections[0].lessons[0];
                }
              }
              this.checkViewedLesson();
              this.detectLastLesson();
            } else {
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              this.messageModalTitle = "Thông báo";
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            this.messageModalTitle = "Thông báo";
          });
    },
    openLesson(index, i, lesson, section) {
      this.currentLesson = lesson;
      this.currentLessonIndex = index + "-" + i;
      this.currentSection = section;
      this.playLesson(this.currentLesson);
      if (window.innerWidth < 1024) {
        this.hideCourseLeft();
      }
    },
    playLesson(lesson) {
      let query = `mutation($course_code: String!, $lesson_code: String!) {
        playLesson(course_code: $course_code, lesson_code: $lesson_code)
      }`;

      ApiService.graphql(query, {course_code: this.course.code, lesson_code: lesson.code});
    },
    addFile() {
      this.$refs.file.click();
    },
    prepareUploadingFile(lesson, section) {
      this.uploadDone = false;
      this.uploadPercent = 0;
      this.currentLesson = lesson;
      this.currentSection = section;
      this.$refs.file.value = null;
    },
    onUploadProgress(progressEvent) {
      this.uploadPercent = Math.round((progressEvent.loaded / progressEvent.total) * 100);
    },
    handleFileUpload() {
      if (!this.$refs.file.files[0]) {
        return;
      }

      let file = this.$refs.file.files[0];

      ApiService.upload(file, {onUploadProgress: this.onUploadProgress})
          .then(({data}) => {
            let lesson_name = this.currentLesson.name;
            let section_name = this.currentSection.name;
            let course_id = this.course.id;
            let query = `mutation {
              createSubmitLesson(media_id: ${data.data.upload.id}, lesson_name: "${lesson_name}", section_name: "${section_name}", course_id: ${course_id}) {
                id
              }
            }`;

            ApiService.graphql(query)
                .then(({data}) => {
                  if (data.data && data.data.createSubmitLesson) {
                    this.uploadDone = true;
                  } else {
                    if (data.errors) {
                      alert(data.errors[0].message);
                    }
                  }
                })
                .catch((response) => {
                  console.log("error", response);
                });
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    handleFinishLesson(lesson, section) {
      let query = `mutation($lesson_id: Int!, $course_id: Int!, $lesson_name: String) {
        viewedLesson(lesson_id: $lesson_id, course_id: $course_id, lesson_name: $lesson_name)
      }`;

      ApiService.graphql(query, {
        lesson_id: parseInt(lesson.id),
        course_id: parseInt(this.course.id),
        lesson_name: lesson.name
      })
          .then(({data}) => {
            if (data.data && data.data.viewedLesson) {
              lesson.viewed = true;
              this.checkViewedSection(section);
              window.$("#finishLessonModalOpen").click();
              this.loadMyCourse();
            }
          })
          .catch((response) => {
            console.log("error", response);
          });
    },
    checkViewedLesson() {
      let viewedLessonIDs = [];

      for (let i = 0; i < this.course.lessonsViewed.length; i++) {
        viewedLessonIDs.push(this.course.lessonsViewed[i].lesson_id);
      }

      if (this.course.sections && this.course.sections.length) {
        for (let i = 0; i < this.course.sections.length; i++) {
          if (this.course.sections[i].lessons && this.course.sections[i].lessons.length) {
            for (let j = 0; j < this.course.sections[i].lessons.length; j++) {
              if (viewedLessonIDs.includes(parseInt(this.course.sections[i].lessons[j].id))) {
                this.course.sections[i].lessons[j].viewed = true;
              }
            }
            this.checkViewedSection(this.course.sections[i]);
          }
        }
      }
    },
    checkViewedSection(section) {
      if (section.lessons && section.lessons.length) {
        let lessonViewed = 0;
        for (let j = 0; j < section.lessons.length; j++) {
          if (section.lessons[j].viewed) {
            lessonViewed++;
          }
        }
        if (lessonViewed === section.lessons.length) {
          section.viewed = true;
        }
      }
    },
    loadMyCourse() {
      let user = getUser();
      let user_id = parseInt(user.id) || 0;

      let query = `query($code: String!, $user_id: Mixed) {
        course(code: $code) {
          users(first: 1, where: {AND: [{column: "users.id", value: $user_id}]}) {
            data {
              name
              uid
              userCoursePivot {
                percent
                last_lesson_id
              }
            }
          }
        }
      }`;

      ApiService.graphql(query, {code: this.$route.params.code, user_id: user_id})
          .then(({data}) => {
            if (data.data && data.data.course) {
              if (data.data.course.users && data.data.course.users.data && data.data.course.users.data.length) {
                this.myCourse = data.data.course.users.data[0];
              } else {
                if (user.role !== "admin") {
                  this.messageModalMessage = "Rất tiếc, bạn chưa được giam gia khóa học này. Vui lòng nhấn tham gia khóa học để vào học";
                  this.messageModalType = "danger";
                  this.messageModalTitle = "Bạn cần tham gia khóa học trước khi vào học";
                  openModal("viewMessageModal");
                  this.backToCourse();
                }
              }
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    backToCourse() {
      this.$router.push({name: "CourseDetail", params: {code: this.$route.params.code}});
    },
    detectLastLesson() {
      if (this.myCourse && this.myCourse.userCoursePivot && this.myCourse.userCoursePivot.last_lesson_id) {
        let last_lesson_id = parseInt(this.myCourse.userCoursePivot.last_lesson_id);

        if (this.course.sections.length) {
          for (let i = 0; i < this.course.sections.length; i++) {
            if (this.course.sections[i].lessons) {
              for (let j = 0; j < this.course.sections[i].lessons.length; j++) {
                if (last_lesson_id === parseInt(this.course.sections[i].lessons[j].id)) {
                  this.currentLessonIndex = i + "-" + j;
                  this.currentSectionIndex = i;
                  this.currentLesson = this.course.sections[i].lessons[j];
                  this.currentSection = this.course.sections[i];
                  return;
                }
              }
            }
          }
        }
      }
    },
    getLessonClass(lesson, index, i) {
      if (this.currentLessonIndex === index + '-' + i) {
        return "mb-2 d-flex justify-content-between align-items-center text-decoration-none text-inherit fw-bold";
      }

      if (lesson.viewed) {
        return "mb-2 d-flex justify-content-between align-items-center text-decoration-none";
      }

      return "mb-2 d-flex justify-content-between align-items-center text-decoration-none text-inherit";
    },
    getLessonIconClass(lesson) {
      if (lesson.viewed) {
        return "fe fe-check fs-6";
      }

      return "fe fe-play fs-6";
    },
    getLessonIconColorClass(lesson, index, i) {
      if (this.currentLessonIndex === index + '-' + i) {
        return "icon-shape bg-light text-inherit icon-sm rounded-circle me-2 fw-bold";
      }

      if (lesson.viewed) {
        return "icon-shape bg-light text-primary icon-sm rounded-circle me-2";
      }

      return "icon-shape bg-light text-inherit icon-sm rounded-circle me-2";
    },
    cutString(str, len) {
      return cutString(str, len);
    },
    hideCourseLeft() {
      window.$("#courseAccordion").removeClass("show");
      window.$("#courseRight").addClass("full");
    },
    toggleCourseRight() {
      if (window.$("#courseRight").hasClass("full")) {
        window.$("#courseRight").removeClass("full")
      } else {
        window.$("#courseRight").addClass("full")
      }
    },
    getPlayerOptions(media, course) {
      return {
        autoplay: true,
        loop: false,
        controls: true,
        fill: true,
        poster: course.thumbnail ? course.thumbnail.link : '',
        sources: [
          {
            src: media.hls,
            type: "application/x-mpegURL"
          }
        ]
      }
    }
  },
  mounted() {
    let user = getUser();
    if (!user) {
      this.$router.push({name: "Login"});
    } else {
      this.loadMySchool();
      this.loadMyCourse();
    }
  },
  computed: {
    isCoccoc() {
      console.log("navigator.....", navigator);

      if (navigator.userAgentData && navigator.userAgentData.brands && navigator.userAgentData.brands.length) {
        for (let i = 0; i < navigator.userAgentData.brands.length; i++) {
          if (navigator.userAgentData.brands[i].brand.toLowerCase() === "coccoc") {
            return true;
          }
        }
      }

      return false;
    },
    showCourseLeft() {
      return window.innerWidth >= 1024;
    }
  }
}
</script>
