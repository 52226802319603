<template>
  <div id="webviewer" ref="viewer"></div>
</template>

<script>
import { ref, onMounted } from "vue";
import WebViewer from "@pdftron/webviewer";

export default {
  name: "WebViewer",
  props: { initialDoc: { type: String } },
  setup(props) {
    const viewer = ref(null);
    onMounted(() => {
      const path = `${process.env.BASE_URL}webviewer`;
      WebViewer({ path, initialDoc: props.initialDoc }, viewer.value).then(
        (instance) => {
          const FitMode = instance.UI.FitMode;
          const Feature = instance.UI.Feature;
          instance.UI.setFitMode(FitMode.FitWidth);
          instance.UI.disableFeatures([Feature.Download]);
          instance.UI.disableFeatures([Feature.Print]);
          instance.UI.disableFeatures([Feature.Annotations]);
        }
      );
    });
    return {
      viewer,
    };
  },
};
</script>
